/* tslint:disable */
/* eslint-disable */
/**
 * Video Builder API
 * API for accepting frames and assembling video files with ffmpeg
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface VideoJob
 */
export interface VideoJob {
    /**
     * 
     * @type {string}
     * @memberof VideoJob
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoJob
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VideoJob
     */
    status?: VideoJobStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof VideoJob
     */
    fps: number;
    /**
     * 
     * @type {number}
     * @memberof VideoJob
     */
    frame?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VideoJobStatusEnum {
    Pending = 'Pending',
    Rendering = 'Rendering',
    Encoding = 'Encoding',
    Complete = 'Complete',
    Error = 'Error'
}

/**
 * 
 * @export
 * @interface VideoJobList
 */
export interface VideoJobList {
    /**
     * 
     * @type {Array<VideoJob>}
     * @memberof VideoJobList
     */
    jobs?: Array<VideoJob>;
}

/**
 * VideoJobsApi - axios parameter creator
 * @export
 */
export const VideoJobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a frame to a video job
         * @param {string} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVideoFrame: async (id: string, body?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addVideoFrame', 'id', id)
            const localVarPath = `/jobs/{id}/frames`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'image/png';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiate the build of the video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildVideo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buildVideo', 'id', id)
            const localVarPath = `/jobs/{id}/build`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new video job
         * @param {VideoJob} [videoJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideoJob: async (videoJob?: VideoJob, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(videoJob, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a specific video job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideoJob: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVideoJob', 'id', id)
            const localVarPath = `/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the finished video file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVideo: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadVideo', 'id', id)
            const localVarPath = `/jobs/{id}/video`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific video job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoJob: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVideoJob', 'id', id)
            const localVarPath = `/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of video jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoJobs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideoJobsApi - functional programming interface
 * @export
 */
export const VideoJobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VideoJobsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a frame to a video job
         * @param {string} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addVideoFrame(id: string, body?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addVideoFrame(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Initiate the build of the video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buildVideo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buildVideo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new video job
         * @param {VideoJob} [videoJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVideoJob(videoJob?: VideoJob, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVideoJob(videoJob, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a specific video job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVideoJob(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVideoJob(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download the finished video file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadVideo(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVideo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a specific video job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideoJob(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVideoJob(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of video jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVideoJobs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VideoJobList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVideoJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VideoJobsApi - factory interface
 * @export
 */
export const VideoJobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VideoJobsApiFp(configuration)
    return {
        /**
         * Add a frame to a video job
         * @param {string} id 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVideoFrame(id: string, body?: any, options?: any): AxiosPromise<VideoJob> {
            return localVarFp.addVideoFrame(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiate the build of the video
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildVideo(id: string, options?: any): AxiosPromise<VideoJob> {
            return localVarFp.buildVideo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new video job
         * @param {VideoJob} [videoJob] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVideoJob(videoJob?: VideoJob, options?: any): AxiosPromise<VideoJob> {
            return localVarFp.createVideoJob(videoJob, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a specific video job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVideoJob(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVideoJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the finished video file
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVideo(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadVideo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific video job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoJob(id: string, options?: any): AxiosPromise<VideoJob> {
            return localVarFp.getVideoJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of video jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVideoJobs(options?: any): AxiosPromise<VideoJobList> {
            return localVarFp.listVideoJobs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VideoJobsApi - object-oriented interface
 * @export
 * @class VideoJobsApi
 * @extends {BaseAPI}
 */
export class VideoJobsApi extends BaseAPI {
    /**
     * Add a frame to a video job
     * @param {string} id 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoJobsApi
     */
    public addVideoFrame(id: string, body?: any, options?: any) {
        return VideoJobsApiFp(this.configuration).addVideoFrame(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiate the build of the video
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoJobsApi
     */
    public buildVideo(id: string, options?: any) {
        return VideoJobsApiFp(this.configuration).buildVideo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new video job
     * @param {VideoJob} [videoJob] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoJobsApi
     */
    public createVideoJob(videoJob?: VideoJob, options?: any) {
        return VideoJobsApiFp(this.configuration).createVideoJob(videoJob, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a specific video job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoJobsApi
     */
    public deleteVideoJob(id: string, options?: any) {
        return VideoJobsApiFp(this.configuration).deleteVideoJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the finished video file
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoJobsApi
     */
    public downloadVideo(id: string, options?: any) {
        return VideoJobsApiFp(this.configuration).downloadVideo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific video job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoJobsApi
     */
    public getVideoJob(id: string, options?: any) {
        return VideoJobsApiFp(this.configuration).getVideoJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of video jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideoJobsApi
     */
    public listVideoJobs(options?: any) {
        return VideoJobsApiFp(this.configuration).listVideoJobs(options).then((request) => request(this.axios, this.basePath));
    }
}


